import * as React from "react";
import { createStore, useStore } from "zustand";

import type { CurrentUserQuery } from "../generated/apollo-hooks";
import {
  useLogoutMutation,
  CurrentUserDocument,
} from "../generated/apollo-hooks";
import { Sentry, sentryClearUser } from "../lib/sentry";
import { posthogClearUser } from "../lib/posthog";
import { client } from "../apollo-client/client";
import { WEB_SITE_URL } from "../constants";

interface AuthStoreProps {
  currentUser?: CurrentUserQuery["currentUser"] | null;
}

interface AuthStoreActions {
  setCurrentUser: (currentUser: CurrentUserQuery["currentUser"]) => void;
  updateCurrentUser: (currentUser: CurrentUserQuery["currentUser"]) => void;
  clearCurrentUser: () => void;
  logout: () => Promise<void>;
}

type AuthStoreState = AuthStoreProps & AuthStoreActions;

const AuthContext = React.createContext<typeof authStore | null>(null);

export const authStore = createStore<AuthStoreState>()((set) => ({
  currentUser: null,
  setCurrentUser: (currentUser) => set(() => ({ currentUser })),
  updateCurrentUser: (updatedUser) =>
    set(() => ({
      currentUser: updatedUser,
    })),
  clearCurrentUser: () => set(() => ({ currentUser: null })),
  logout: () => {
    throw new Error("Logout function action is not implemented");
  },
}));

// Fetch current user as immediately as possible using iife
(function fetchCurrentUserImmediately() {
  client
    .query<CurrentUserQuery>({
      query: CurrentUserDocument,
    })
    .then(({ data }) => {
      authStore.getState().setCurrentUser(data.currentUser);
    });
})();

export const AuthProvider = (props: { children?: React.ReactNode }) => {
  const { children } = props;
  const [logoutMutation] = useLogoutMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CurrentUserDocument }],
  });

  const logout = async () => {
    try {
      const res = await logoutMutation();
      if (res.data?.logout === true) {
        await client.resetStore();
        authStore.getState().clearCurrentUser();
        sentryClearUser();
        posthogClearUser();
        // Redirect to site
        window.location.replace(WEB_SITE_URL);
      }
    } catch (error) {
      await client.resetStore();
      Sentry.captureException(error);
      window.location.replace("/");
    }
  };

  const [authStoreValue] = React.useState(() => {
    authStore.setState(() => ({ logout }));
    return authStore;
  });

  return (
    <AuthContext.Provider value={authStoreValue}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth<T>(selector: (state: AuthStoreState) => T): T {
  const store = React.useContext(AuthContext);
  if (!store) throw new Error("Missing Auth Provider");
  return useStore(store, selector);
}

export const useUser = () => {
  const currentUser = useAuth((state) => state.currentUser)!;
  return currentUser;
};
